.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: #321fdb;
  background: transparent;
}// Here you can add other styles

.c-sidebar .c-sidebar-nav-title {
  color: #321fdb;
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #321fdb;
  background: rgba(255, 255, 255, 0.05);
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #321fdb;
}

thead[class*="ant-table-thead"] th{
  background-color: #69b1ff !important;
  color : white !important;
}


.table-row-blue {
  background-color: #CCCAFB;
}
.table-row-blue2 {
  background-color: #69b1ff;
  color:white;
}


.table-row-green {
  background-color: #CCF9D2;
}
.table-row-yellow {
  background-color: #FEFFA0;
}
.table-row-red {
  background-color: #FFABAB;
}
.table-row-white {
  background-color: #FFFFFF;
}

.highlight-bottom-border > td {
  border-bottom: solid 1px black !important
}

.table-row-bold {
  font-weight: 600;
}

.table-row-right {
  text-align:right;
}

.kirikanan .kiri{
  float :left;
  }

  .kirikanan .kanan{
    float :right;
    margin-right: 15px;
    }

  .kirikanan .hapus{
    clear: both;
  }

  .c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
    color: rgba(255, 255, 255, 1);
    background:#321fdb;
}

.labeldaninput {
  margin: 0 5px 17px 15px;
  width: 570px;
}

.visibledevice {display:none;}
.visibledesktop {display:display;}

@media (max-width : 600px) {
    .visibledevice {display:block;}
    .visibledesktop {display:none;}
}

.c-app {
  background-color: white;
}

.labeldaninput .labelatas{
  margin-bottom: 5px;
}

.kotakdashboard2 {
  /* border-color:black; */
  border: solid rgb(235, 222, 222);
  border-width: 2px 2px 2px 2px;
  border-radius: 8px;
  width: 100%;
  background-color: white;
  position: relative;
  padding : 15px 15px 15px;
  margin : 150px 0px 0px 150px;
  width : 70%;
}


.kotakdashboard {
  /* border-color:black; */
  border: solid rgb(235, 222, 222);
  border-width: 2px 2px 2px 2px;
  border-radius: 8px;
  width: 100%;
  background-color: white;
  position: relative;
  padding : 15px 15px 15px;
  margin-bottom:10px;
}
// Here you can add other styles
.c-sidebar .c-sidebar-brand {
  color: #fff;
  background: #fff;
}

.c-main {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 0;
  padding-top: 2 * $spacer;

  > .container-fluid {
    @include media-breakpoint-up(md) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}

.sigCanvas {
  border: 1px solid purple;
}

@font-face {
  font-family: 'Source Han Sans';
  src: local('Lato'), url(./../fonts/SourceHanSansCN-Normal.otf) format('opentype');
}



